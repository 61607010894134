<style scoped>
</style>

<template>
  <div class="vue-box">
    <!-- 参数栏 -->
    <div class="c-panel">
      <div class="c-title">用户添加</div>
      <el-form size="mini" v-if="m">
        <el-form-item label="昵称:">
          <el-input v-model="m.nikeName"></el-input>
        </el-form-item>
        <el-form-item label="用户名:">
          <el-input v-model="m.username"></el-input>
        </el-form-item>
        <el-form-item label="密码:">
          <el-input v-model="m.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="角色:">
          <el-select v-model="m.role_id">
            <el-option label="请选择" :value="0" disabled></el-option>
            <el-option label="商户" :value="1"></el-option>
            <el-option label="码队" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span class="c-label">&emsp;</span>
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="ok">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['params'],
  data() {
    return {
      m: {
        nikeName: '',
        username: '',	// 从菜单配置文件里传递过来的参数 
        password: '',
        role_id: 0
      }
    }
  },
  methods: {
    ok: function () {
      this.sa.ajax('/v1/admin/addMerchant', this.m, function () {
        this.sa.ok("添加成功");
      }.bind(this))
    }
  },
  created() {

  }
}
</script>


